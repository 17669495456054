import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conact',
  templateUrl: './conact.component.html',
  styleUrls: ['./conact.component.scss']
})
export class ConactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
