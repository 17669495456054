
<nav class="navbar navbar-expand-lg navbar-light form-inline " >
    <img src="../../assets/logo.jpg" class="logo-img">
        <a class="navbar-brand name" href="#">
            <div class="row">
                <div><h1>Hindustan Labels</h1></div>
                <div class="col-sm-12"><h6>' We represent our coustomer ' <a href="../../assets/New Company Profile.pdf" download><img src="../../assets/download.svg" height="19px" width="20px"> company profile</a></h6></div>
            </div>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        aria-expanded="false" aria-label="Toggle navigation"
       (click)="toggleCollapsed()">
       <span class="navbar-toggler-icon"></span>
     </button>
  
    <div class="collapse navbar-collapse" [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
  
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <ul class="navbar-nav">
          <li class="nav-item" >
            <a class="nav-link" [routerLink]="['/aboutUs']" href="aboutUs"><b>About us</b></a>
          </li> 
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/Sustainability']" routerLinkActive="active" href="Sustainability"><b>Sustainability</b></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" href="#"><b>Home</b></a>
          </li>
          <li class="nav-item">
              <a class="nav-link" [routerLink]="['/portfolio']" routerLinkActive="active" href="#"><b>Portfolio</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/Operation']" routerLinkActive="active" href="#"><b>Operations</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/contact_us']" routerLinkActive="active" href="#"><b>Contact Us</b></a>
            </li>
        </ul>
      </form>
    </div>
  </nav>
