<app-header></app-header>
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14988.48998525552!2d73.8952982!3d20.0871656!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x625ecd3b96f5a480!2sHindustan%20Labels!5e0!3m2!1sen!2sin!4v1634576956180!5m2!1sen!2sin" 
style="border:0;" allowfullscreen="" loading="lazy"></iframe>

<div style="background-color: rgba(208, 213, 218, 0.473);padding: 70PX 70PX;">
    <div class="form">
        <section class="mb-4">
            <h2 class="h1-responsive font-weight-bold text-center my-4" style="font-family: 'Croissant One', cursive;">
                Contact us</h2>
            <div class="row">
                <div class="col-md-12 mb-md-0 mb-5">
                    <form #enquiry="ngForm" (ngSubmit)="onSubmit(enquiry.value)">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="md-form mb-0">
                                    <input type="text" minlength="5" #name='ngModel' id="name" name="name" class="form-control" required ngModel>
                                <label for="name" class="">Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="md-form mb-0">
                                    <input type="text"  #company_name='ngModel' id="email" name="company_name" class="form-control" required ngModel>
                                    <label for="email" class="">company names</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="md-form mb-0">
                                    <input type='text' #number='ngModel' limit = 10 class='form-control' name = 'number' pattern='[0-9]{10}'
                                    maxlength = '10' required ngModel>
                                    <label for="name" class="">Phone Number</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="md-form mb-0">
                                    <input type="text" email id="email" #email='ngModel' name="email" class="form-control" required ngModel>
                                    <label for="email" class="">Email</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="md-form">
                                    <textarea type="text" minlength="10" #message='ngModel' id="message" name="message" rows="2"
                                        class="form-control md-textarea" required ngModel></textarea>
                                    <label for="message">Your message</label>
                                </div>
                            </div>
                        </div>
                        <div class="text-center text-md-left mt-2">
                            <button [disabled]="enquiry.invalid" class="btn btn-primary" type="submit">Submit</button>
                            </div>
                    </form>
                    
                    <div class="status"></div>
                </div>
            </div>
        </section>
    </div>
</div>
<app-footer></app-footer>