<app-header></app-header>

<body>
    <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
        [showNavigationIndicators]="showNavigationIndicators" data-interval="10000">
        <ng-template ngbSlide *ngFor="let image of images">
            <div class="picsum-img-wrapper">
                <img [src]="image" class="img" alt="Random slide">
            </div>
            <div class="carousel-caption">
            </div>
        </ng-template>
    </ngb-carousel>



    
        <div class="f_part">
            <div class="container-fluid">
            <h1>WHY WE?</h1>
            <h2>YOU THINK ! WE PRINT.</h2>

            
                <i class="fa fa-check-square-o fa-1x"></i> Our Job is to educate people about to use of sustainable
                packaging....
                we don't think about only sales but also how to recycle the packaging
                waste after use.
                <br>            
            
                <i class="fa fa-check-square-o fa-1x"></i>HINDUSTAN Labels a emerging name in the era of 'Industrial
                label need, Shrink Sleeves and packaging pouches Converting. Our
                focus is always on analyzing customer requirement and offer total
                satisfactory quality solution for Self adhesive labeling requirements
                in their schedule time and budgeted pricing. We have top range
                of clients in the field of <b>FMCG, AUTOMOBILE, LUB AND OIL, INDUSTRIAL AUTOMATION,
                    COSMATIC, PHARMA, TOY'S & Many more...</b>
                </div>
        </div>
        <div class="s_part">
            <div class="container-fluid" >
             <b>VISION:</b> <br>
                “ We want to be the most sustainable Label Converter among
                the Printing and sustainable Packaging industries with the help of
                highly skilled force and most modern technology.
            </div>
        </div>
        <div class="t_part">
            <div class="container-fluid" >
            <b>MISSION:</b> <br>
            “ALWAYS TO BE THE INVOTAIVE IN WHAT WE DELIVERR TO
            OUR PRECIOUS CLIENT GLOBELY IN THE FIELD OF
            SUSTANABLE PACKAGING”
            
            
                - By Providing Innovative solutions for Self adhesive sticker
                labels , PVC Sleeves and Packaging pouch with quality material
                within client schedule time. With the help of Human Passionate
                skill and artificial Intelligence in the field of sustainable Printing.
            </div>
        </div>
        <div class="f_part">
            <div class="container-fluid" >
            <b>MISSION STATEMENT:</b> <br>
            “To be the leading prime label supplier to the consumer goods
            marketplace by offering a comprehensive and innovative suite
            of label solutions to consistently meet our objectives while
            providing our associates opportunities to enrich themselves
            and their careers.”
        </div>
        </div>

    <app-footer></app-footer>
</body>