import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ImgCarouselComponent } from './img-carousel/img-carousel.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { FooterComponent } from './footer/footer.component';
import { OperationComponent } from './operation/operation.component';
import { LogoSliderComponent } from './logo-slider/logo-slider.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SwiperModule } from 'swiper/angular';
import { ConactComponent } from './conact/conact.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    ContactUsComponent,
    HeaderComponent,
    HomeComponent,
    ImgCarouselComponent,
    PortfolioComponent,
    SustainabilityComponent,
    FooterComponent,
    OperationComponent,
    LogoSliderComponent,
    ConactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    IvyCarouselModule,
    SwiperModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
