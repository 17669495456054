<!-- <div style="margin : 5%;">
    <carousel>
        <div class="carousel-cell ml-5" style="padding: 10px 10px;" *ngFor="let image of images">
            <img [src]="image" height="20px">
        </div>
    </carousel>
</div> -->

<!-- <div class="carousel">
    <div class="carousel-cell" *ngFor="let image of images">
        <img [src]="image" height="20px" class="ml-2 mr-2">

    </div>

  </div>
   -->
   <div class="slider">
	<div class="slide-track">
		<div class="slide carousel-cell ml-5"  *ngFor="let image of images">
            <img [src]="image" height="180px">
		</div>
	</div>
</div>