<app-header></app-header>

<div class="img">
<img src="../../assets/nature.gif">
<!-- <div class="container"> </div> -->
</div>
<div class="container-fluid" style="background-color: rgba(208, 213, 218, 0.747); padding: 60px 30px;line-height: 50px;">
<div class="sus-heading" style="text-align: center;"><h1><b>FROM SUBSTRATES TO PROCESSES...</b></h1></div>
<div class="sus-sub-heading">
<h3>
    Our Product Design And Sourcing effort are based upon 
    established product design criteria
   
</h3>
</div>
<div class="row design-data">
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x"></i> Require that to the extent 
        practical,our suppliers provide raw 
        material that meets criteria for 
        performance and cost
    </div>
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x "></i> Optimize the use of alternative & renewable energy in 
        sourcing, manufacture, transportation and recycling of labels.
    </div>
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x"></i> Maximized the use of renewable or recycled source materials.
    </div>
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x"></i> Is manufactured using clean production technologies and best
        environment practices.
    </div>
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x"></i> Longer team raw materials should conform to being healthy in all probable end of life scenarios.
    </div>
    <div class="col-sm-4">
        <i class="fa fa-check-square-o fa-1x"></i> Is physically designed to optimize materials and energy.
    </div>
</div>
</div>
<div class="container-fluid"  style="background-color: rgba(195, 198, 201, 0.664); padding: 60px 30px;line-height: 50px;">
<div class="sus-text-lastdata">
    <b><h1>Sustainability Options</h1></b>
    <h3>Hindustan Labels offers a variety of sustainable options (from substrates
        to processes) or each of our label formats and print technologies we
        provide including, but not limited to:</h3>
</div>
<div class="row">
    <div class="col-lg-3 mt-5"><img src="../../assets/sustainability1.png"></div> 
    <div class="col-lg-3 mt-5"><img src="../../assets/sustainability2.png"></div>    
    <div class="col-lg-3 mt-5"><img src="../../assets/sustainability3.png"></div>    
    <div class="col-lg-3 mt-5"><img src="../../assets/sustainability4.png"></div>    
</div>
</div>
<app-footer></app-footer>
