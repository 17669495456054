import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styleUrls: ['./img-carousel.component.scss']
})
export class ImgCarouselComponent implements OnInit {
  showNavigationArrows = true;
  showNavigationIndicators = true;
  images = ['f','i',14,11,6].map((n) => `../../assets/home/${n}.jpg`);
  constructor() { }

  ngOnInit(): void {
  }

}
