import { Component, OnInit } from '@angular/core';
import SwiperCore from 'swiper/core';
@Component({
  selector: 'app-logo-slider',
  templateUrl: './logo-slider.component.html',
  styleUrls: ['./logo-slider.component.scss']
})
export class LogoSliderComponent implements OnInit {
  
  images = [1,2,3,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46].map((n) => `../../assets/client_logo/${n}.jpg`);
  constructor() { }
  
  ngOnInit(): void {}
}
