import { OperationComponent } from './operation/operation.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { LogoSliderComponent } from './logo-slider/logo-slider.component';
import { ConactComponent } from './conact/conact.component';

const routes: Routes = [
  { path: 'aboutUs', component: AboutUsComponent },
  { path: 'Sustainability', component: SustainabilityComponent },
  { path: 'home', component: HomeComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'Operation', component: OperationComponent },
  { path: 'contact_us', component: ContactUsComponent },
  { path: 'logo', component: LogoSliderComponent },
  { path: '',   redirectTo: '/Sustainability', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
