<app-header></app-header>
<app-img-carousel></app-img-carousel>
<div class="container-fluid f_part" >
    <div style="text-align: center;">
        <h1>THE AIKYAM GROUP, INDIA</h1>
    </div>
    <div class="row">
        <div class="col-sm-5">
            <img class="ml-5 mb-4"src="../../assets/unnamed.jpg">
        </div>
        <div class="col-sm-7">
            &nbsp;&nbsp;&nbsp;&nbsp;This group is established in 2014 with the great vision of leading the
            Businesses in varies Industries With the help of our skilled Man force,
            Most modern Technology and our Visionary leaders decision making
            & forward planing.
        </div>
    </div>
</div>


<div class="container-fluid s_part" >
    <div style="text-align: center;">
        <h1>HINDUSTAN LABELS, INDIA.</h1>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <img src="../../assets/logo.jpg">
        </div>
        <div class="col-sm-6">

            Hindustan Labels is a leading supplier of high impact decorative
            self adhesive labels and Shrink wrap sleeves. specializing in a
            variety of solu ons to fit your product packaging needs.
            We are one of the leading manufacturer and suppliers of high
            quality flexible packaging films and labels. Growing industries
            like FMCG, Pharmaceuticuls, Food processing, Breweries,
            Edible Oil, Automobile, Oil and Lub.
            Our manufacturing company has been extraordinarily careful
            and consistent in our quality, delivery, pricings and catering
            to the customer's minutest needs.
        </div>
        <div class="col-sm-12"> We truly believe our good
            name is extremely important to us so we don't let a single
            customer go dissatisfied. As one stop shop Film and Seld
            adhesive paper label manufacturerswe have most modern
            label printing and hi speed converting machines.
            The company is outfitted with an outstanding technical setup and
            facilities to churn out the most advanced range of products for
            its valued clients. Our factory is located at Janori, near cargo
            airport, Nashik, Which helping us in providing quality solutions in
            quick time at highly economical prices to our international clients.

            With the help of our rich technical experience and expertise knowledge,
            we can design and create any type of labels and stickers for your specific
            requirements and can also provide various services related to label printing.
        </div>
    </div>
</div>

<app-footer></app-footer>