<footer>
    <div class="row">
        <div class="col-sm-4">
            <h2><b> Get in touch </b></h2>
         <a href=" ">   <img src="../../assets/icons8-facebook-48.png" width="6%"> facebook </a><br>
           <a href= "https://mail.google.com/mail/?view=cm&fs=1&to=Sales@hindustanlabels.in"> <img src="../../assets/icons8-gmail-48.png" width="6%"> Sales@hindustanlabels.in </a> <br>
           <a href="tel:+91 9370990346"> <img src="../../assets/icons8-ringer-volume-96.png" width="6%"> +91 9370990346</a>
        </div>
        <div class="col-sm-4">
            <h2><b> Our info </b></h2>
            <a href="aboutUs"> About us <br></a>
            <a href="Sustainability">Sustainability <br></a>
            <a href="home"> Home <br></a>
            <a href="portfolio"> Portfolio <br></a>
            <a href="Operation"> Operation <br></a>
            <a href="contact_us"> Contact us <br></a>
        </div>
        <div class="col-sm-4">
            <h2><b>Our location</b></h2>
            FACTORY- UNIT 1 - Godown No. 15, Ashapura Godown,G. No. 591/2, 
            Opp. Marshman Comp, Janori Road, Janori, Tal. Dindori Dist. Nashik (MH) - 422207
        </div>
    </div>
  </footer>