import { Component, OnInit } from '@angular/core';
import '../../assets/js/smtp.js';
declare let Email : any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() { }
  onSubmit(data:any)
  {
    console.log('"'+data.name+'"');
    Email.send({
      Host : "webmail.hindustanlabels.in",
      Username : "sales@hindustanlabels.ine",
      Password : "Sales_2021",
      To : 'sales@hindustanlabels.in',
      From : data.email,
      Subject : "Enquiry From"+data.company_name,
      Body : "Name:"+data.name+",<br>"+
             "Company Name:"+data.company_name+
             ",<br>Contact Number:"+data.phone_number+","+
             "<br>Email:"+data.email+
             ",<br>Body:"+data.message
  }).then(
     alert("Thank You "+data.name)
  );
    
    
     
  }

  ngOnInit(): void {
  }

}
