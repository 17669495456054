<app-header></app-header>

<body>
    <div class="container-fluid" style="background-color: rgba(208, 213, 218, 0.747);padding: 50px 50px;line-height: 50px;">
        <div class="lebel-info">
            <h2>1. PRESSURE SENSITIVE PRODUCT LABELS.</h2>
            <p>
                - Pressure sensitive labels offer a high-end look that's perfect
                for showing off your product.
            </p>
            <img src="../../assets/Operation/B.jpg" class="mb-3">
            <p>
                - For single label applications, front and back labeling, or that
                classy no-label look, pressure sensitive labels can be a great
                choice for nearly any container type and shape. Depending
                on your facilities, pressure sensitive labels can be applied by
                hand or machine.
            </p>
            <p>
                - <b>Why Hindustan Labels for your roll-To-Roll label needs?</b><br>
                We offer printing in up to 10 colors, plus multiple coating options that are
                not only eye catching, but also durable and scuff resistant. We
                provide the latest print technologies to meet your production
                volume requirements.
            </p>
            <p>
                <b>
                    - changeovers and shipping weight <br>
                    - PLA Facestock: renewable resource based film <br>
                    - Recyclable: easily separated from PET containers <br>
                    - in recycling systems <br>
                    - Recycling (liners): sources for PET liners <br>
                </b>
            </p>
        </div>
    </div>
    <div class="container-fluid" style="background-color: rgba(189, 189, 197, 0.541);padding: 50px 50px;line-height: 50px;">
        <div class="lebel-info">

            <h2>2. SHRINK SLEEVE LABELS.</h2>
            <p>
                - We at HINDUSTAN LABELS developed a variety of shrink sleeve
                options that support recycling efforts and can meet APR
                (Association of Plastic Recyclers) guidelines, making them a win
                for brand owners and the environment.
            </p>
            <img src="../../assets/Operation/A.jpg" >

        </div>

    </div>
</body>
<app-footer></app-footer>