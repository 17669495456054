
<app-header></app-header>
<img src="../../assets/portfolio_img1.png">
    <div class="container-fluid" style="background-color: rgba(188, 194, 199, 0.747);padding: 90px;">



        <h1 style="color: rgb(126, 0, 21);">BUILD YOUR BRAND.....</h1>
        <p>
            <i class="fa fa-check-square-o fa-1x"></i> Brand building with 
            sustainability goals. BOOST BRAND VISIBILITY with Customized Pressure Sensitive Product labels and shrink
            wrap sleeves.
        </p>
        
        <div class="row mt-5">
            <div class="col-sm-4">
                <p><i class="fa fa-question"></i><b>. DO YOU SEE WHAT IS PACKED INSIDE......?</b> </p>
            </div>
            <div class="col-sm-3">
                <p><i class="fa fa-question"></i><b>. WILL YOU ABLE TO BUY...? </b></p>
            </div>
            <div class="col-sm-5">
                <p><i class="fa fa-question"></i><b>. DO YOU GET THE PRODCUT INFORMATION & COST......? </b></p>
            </div>
            <div class="col-sm-12" style="text-align: center;">
                <h2> Answer to all above question is NO ...... <i class="fa fa-frown-o"></i></h2>
            </div>
        </div>
        <div class="col-sm-12">
            <h2>TO GET THE ANSWER YES TO ALL ABOVE QUESTION
                YOU NEED OUR PRODUCT....YES THAT IS LABEL....</h2>
        </div>

<app-logo-slider></app-logo-slider>
</div>
<app-footer></app-footer>